import * as Sentry from "@sentry/browser"

document.addEventListener("DOMContentLoaded", () => {
  if (document.head.querySelector("meta[name='sentry-dsn']")) {
    Sentry.init({
      dsn: document.head.querySelector("meta[name='sentry-dsn']").content,
      release: document.head.querySelector("meta[name='sentry-release']")
        .content,
      environment: document.head.querySelector(
        "meta[name='sentry-environment']"
      ).content,
    })
  }
})
